import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../../actions/dialog';
import Content from './Content';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from 'tss-react/mui';
import notifSystem from '../../../notifSystem';
import { getCaldeoData } from '../../../actions/caldeo';
import { fromJS, List } from 'immutable';
import { calculateDomofinance } from '../../../utils/financement';
import api from '../../../api';

const styles = {
  loadingContainer: {
    textAlign: 'center'
  }
};

const setCaldeo = setDialogContentProp('data');
const setErrors = (v) => setDialogContentProp('setErrors')(v);

const mapStateToProps = ({
  dialog: {
    contentProps: {
      merge,
      devisId,
      type,
    },
  },
  userId,
  users,
}) => ({
  merge,
  devisId,
  type,
  user: users && users.find(e => e._id === userId),
});

const mapDispatchToProps = {
  setCaldeo,
  setErrors
};

const paths = {
  surface: ['surface'],
  ceeAmount: ['ceeAmount'],
  anahAmount: ['anahAmount'],
  chaudiereType: ['combination', 'chaudiereType'],
  oldChaudiereType: ['combination', 'oldChaudiere', 'type'],
  dependent: ['dependent'],
  householdStatus: ['householdStatus'],
  amount: ['domofinance', 'amount'],
  report: ['domofinance', 'report'],
  devisDate: ['date', 'caldeoDevis'],
  factureDate: ['date', 'caldeoFacture'],
  comboType: ['combination', 'comboType'],
  zipCode: ['client', 'address', 'zipcode'],
  ownerProfile: ['ownerProfile'],
  houseType: ['houseType'],
  ageHouse: ['ageHouse'],
  houseTotalRevenues: ['houseTotalRevenues'],
  chauffeEauThermSimulation: ['chauffeEauThermSimulation'],
  pompeAChaleurSimulation: ['pompeAChaleurSimulation'],
};

class CaldeoDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      merge: props.merge || new List(),
      tabValue: 0,
      origChaudieres: new List(),
      loading: false,
      chaudiereList: [],
      choosenChaudiere: {},
      errors: [],
      previewLoading: false,
      simulationError: false,
      simulationLoading: false,
      simulationHelps: []
    };
  }

  getData = () => {
    const { devisId, merge: parentMerge, setCaldeo } = this.props;
    getCaldeoData(devisId).then(data => {
      if (data) {
        const {
          products, combination, signature,
          client, billing, domofinance
        } = parentMerge.toJS();
        this.setState({
          merge: fromJS({
            ...data,
            ...(products ? { products } : {}),
            ...(combination ? { ...{ combination,
            } } : {}),
            ...(signature ? { signature } : {}),
            ...(client ? { client } : {}),
            ...(billing ? { billing } : {}),
            ...(domofinance && (domofinance || {}).amount > 0 ?
              { domofinance } : {}),
          }),
        }, () => {
          setCaldeo(this.state.merge);
        });
      }
    }).catch(() =>
      notifSystem.error('erreur system', 'Donnée non récuperé'));
  }

  componentDidMount() {
    const { type, devisId } = this.props;
    if (type === 'interventions' && devisId) {
      this.getData();
    }
    this.props.setErrors((errors) => this.setState({
      errors
    }));
  }

  setLoading = (loading) => {
    this.setState({
      loading
    });
  }

  setLoadingPreview = (previewLoading) => {
    this.setState({
      previewLoading
    });
  }

  setData = (path, value, cb) => {
    const { setCaldeo } = this.props;
    if (path[1] === 'chaudiereType') {
      value = Number(value)
      this.setState((prevState) => ({
        [path[1]]: value,
        merge: prevState.merge.setIn(path, value)
      }), () => setCaldeo(this.state.merge));
    }
    this.setState((prevState) => ({
      merge: prevState.merge.setIn(path, value)
    }), () => {
      if (cb) {
        cb();
      }
      if ([paths.ceeAmount[0], paths.anahAmount[0]]
        .includes(path[0])) {
        calculateDomofinance(this.state.merge, this.setData);
      }
      setCaldeo(this.state.merge);
    });
  };

  getSimulationResult = () => {
    const devis = this.state.merge.toJS();
    if (!devis.pompeAChaleurSimulation && !devis.chauffeEauThermSimulation) {
      return notifSystem.error('Type de chaudiere non séléctionné',
        'Veuillez selectionner le(s) type de chaudiere')
    }
    this.setState({
      simulationLoading: true
    });
    api.devis.custom('simulationAide').post({
      dependent: devis.dependent,
      houseType: devis.houseType,
      clientZipCode: devis.client.address.zipcode,
      ownerProfile: devis.ownerProfile,
      ageHouse: devis.ageHouse,
      houseTotalRevenues: devis.houseTotalRevenues,
      pompeAChaleurSimulation: devis.pompeAChaleurSimulation,
      chauffeEauThermSimulation: devis.chauffeEauThermSimulation
    }).then(resp => {
      const data = resp.body().data() || {};
      if (data) {
        this.setData(paths.ceeAmount,
          data.ceeAmount === 0 &&
          devis.pompeAChaleurSimulation
            ? 250000.00 : data.ceeAmount);
        this.setData(paths.anahAmount, data.anahAmount);
      }
      this.setState({ simulationHelps: (data || {}).helps || [] });
      this.setState({ simulationError: !(data?.helps || []).length });
    }).catch(() => {
      this.setState({ simulationError: true });
    }).finally(() => {
      this.setState({ simulationLoading: false });
    });
  }

  handleTabChange = (e, newVal) => {
    this.setState({ tabValue: newVal });
  }

  render() {
    const { classes, user, type } = this.props;
    const {
      errors, loading, tabValue, merge, previewLoading,
      ...state
    } = this.state;
    return loading ?
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
      : <Content
        paths={paths}
        errors={errors}
        setData={this.setData}
        saveData={this.saveData}
        handleTabChange={this.handleTabChange}
        tabValue={tabValue}
        merge={merge}
        user={user}
        parentState={state}
        setLoading={this.setLoading}
        setLoadingPreview={this.setLoadingPreview}
        previewLoading={previewLoading}
        type={type}
        getSimulationResult={this.getSimulationResult}
      />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(CaldeoDialog, styles));
